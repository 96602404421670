<template>
  <div class="relative md:pt-32 pb-32 pt-12">
    <div class="w-full mb-12 px-4">
      <card-bill/>
    </div>
  </div>
</template>
<script>
import CardBill from '@/components/Cards/CardBill.vue'

export default {
  components: {
    CardBill
  }
}
</script>
